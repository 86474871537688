import React, { Component } from "react"
import Footer from "../components/footer"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Thankyou extends Component {

  render() {
    return (
        <Layout>
         <SEO title="Welcome to InTow Connect" />
        <section className="section thank-you-area  ptb-60">
        <div className="container text-center">
          <div className="row mb-40">
            <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12 col-xs-center">
              <h1 className="text-center col-md-12 col-sm-12">Thank you</h1>
              <i className="fa fa-check fa-5x  mb-4 contact-icon main-content__checkmark" id="checkmark"></i>
              <p className="text-center">Thank you for registering your company with InTow Connect.  Your email address is now registered.  You can now login and setup your Users and Providers.</p>
            </div>
          </div>
          <div className="row mb-20 text-center">
          <div className="p-20 col-md-12 pt-4">
                <a className="text-center backtoHome" href="/">
                  Back to HomePage
                </a>
              </div>
          </div>

        </div>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default Thankyou
